.levelReportMain{
  padding: 10px;
  background-color: #F1F1F1;
}
.levelReportContainer {
  max-width: 100%;
  margin: 0 auto;
}

.reportHeadingMain {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.13px;
  text-align: left;
  color: rgba(88, 88, 87, 1);
  margin-bottom: 20px;

  
}
.cardsMain{
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}
.cardsContainer{
  background-color:rgb(52,152,219) ;
  align-items: flex-end;
  width: 100%;
display: flex;
justify-content: space-between;
padding-left: 0px;
padding-bottom: 10px;
padding-right: 20px;
height: 160px;
}
.cardsContainer2{
  background-color:rgb(52,183,95) ;
  align-items: flex-end;
  width: 100%;
display: flex;
justify-content: space-between;
padding-left: 20px;
padding-bottom: 20px;
padding-right: 20px;
height: 160px;

}
.cardsContainer3{
  background-color:rgb(225,77,67) ;
  align-items: flex-end;
  width: 100%;
display: flex;
justify-content: space-between;
padding-left: 20px;
padding-bottom: 20px;
padding-right: 20px;
height: 160px;

}
.rankIcn{
  width: 110px; 
  height: 110px;
  color: rgb(93,173,226);
}
.ArrowUpIcn{
  width: 80px; 
  height: 80px;
  color: rgb(93,197,127);
}
.ArrowDownIcn{
  width: 80px; 
  height: 80px;
  color: rgb(231,113,105);
}
.cardText1{
  font-family: Roboto;
font-size: 38px;
font-weight: 600;
line-height: 18.75px;
text-align: right;
color: #FFFF;
padding-bottom: 20px;
}
.cardText2{
  font-family: Roboto;
font-size: 16px;
font-weight: 600;
line-height: 18.75px;
text-align: center;
color: rgba(133, 202, 248, 1);
padding-bottom: 20px;


}
.cardText3{
  font-family: Roboto;
font-size: 16px;
font-weight: 600;
line-height: 18.75px;
text-align: center;
color: rgba(130, 231, 163, 1);
padding-bottom: 10px;


}
.cardText4{
  padding-bottom: 10px;
  font-family: Roboto;
font-size: 16px;
font-weight: 600;
line-height: 18.75px;
text-align: center;
color: rgba(249, 165, 159, 1);


}


.graphMain{
  display: flex;
  gap: 20px;
margin-top: 20px;
}
.graphContainer{
background-color:rgb(255,255,255);
border: 1px solid rgba(207, 207, 207, 0.767) ;
width: 100%;
}
.graphdateMain{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  border-bottom:1px solid rgba(207, 207, 207, 0.767) ;


}
.graphDate{
  font-family: Roboto;
font-size: 15px;
font-weight: 600;
line-height: 12.89px;
text-align: center;
color: rgba(88, 88, 87, 1);


}
.graphDate span{
  font-family: Roboto;
font-size: 11px;
font-weight: 500;
line-height: 12.89px;
text-align: center;
color: rgba(88, 88, 87, 1);


}
.graphDateIcon{
  display: flex;
}
.leftPagMain{
  padding: 4px;
  cursor: pointer;
  border:1px solid rgba(207, 207, 207, 0.767) ;

}
.leftarrowIcn{
  width: 22px;
height: 22px;
}
.graphButtonMain{
  border-bottom:1px solid rgba(207, 207, 207, 0.767) ;
}
.graphBtn{
  width: 58px;
height: 36px;
background: rgba(249, 249, 249, 1);
font-family: Roboto;
font-size: 11px;
font-weight: 600;
line-height: 12.89px;
text-align: center;
color: rgba(88, 88, 87, 1);
border:1px solid rgba(207, 207, 207, 0.767) ;
}
.graphBtn2{
  width: 58px;
height: 36px;
background: rgba(249, 249, 249, 0);
font-family: Roboto;
font-size: 11px;
font-weight: 600;
line-height: 12.89px;
text-align: center;
color: rgba(88, 88, 87, 1);
border:1px solid rgba(207, 207, 207, 0.767) ;
}

.ChartsMain{
border-bottom:1px solid rgba(207, 207, 207, 0.767) ;
  
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.graphbottomText{
  padding-top: 15px;
  font-family: Roboto;
font-size: 16px;
font-weight: 600;
line-height: 15.23px;
text-align: center;
color: rgba(88, 88, 87, 1);

}
.graphbottomText2{
  padding-top: 5px;
  font-family: Roboto;
font-size: 10px;
font-weight: 400;
line-height: 15.23px;
text-align: center;
color: rgba(88, 88, 87, 1);

}
.graphbottomText4{
  padding-top: 5px;
  font-family: Roboto;
font-size: 10px;
font-weight: 400;
line-height: 15.23px;
text-align: center;
color: rgb(192, 56, 56);

}
.graphbottomText3{
  padding-top: 5px;
  padding-bottom: 20px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.23px;
  text-align: center;
  
color: rgba(88, 88, 87, 1);

}
.ChartsMain canvas {
  width: 100% !important;
  height: 300px !important;
}


.tableMain{
  width: 100%;
margin-top: 20px;
display: flex;
gap: 20px;
}
  .tableContainer{
    width: 100%;
background-color: #FFFF;
padding-top: 10px;
padding-bottom: 10px;
  }
 .pagiMain{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

 }
.tableHeadings{
  font-family: Roboto;
font-size: 12px;
font-weight: 600;
line-height: 14.06px;
text-align: center;
color: rgba(88, 88, 87, 1);

}

.tableTextFirst{
  font-family: Roboto;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.23px;
  text-align: center;
  color: rgba(6, 61, 168, 1) !important;

}
.tableTextTwo{
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.06px;
  text-align: center;
  color: rgba(151, 151, 155, 1) !important;

}
.tableMain2 table th{
  padding: 10px;
  text-align: center;
  border-left: 0px;
  border-right: 0px;
}
.tableMain2 table td {
  padding: 10px;
  text-align: center;
  border: 0px;
}
.pagiMain{
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(207, 207, 207, 0.767) ;

}
.pagiMain2{
  border-top: 1px solid rgba(207, 207, 207, 0.767) ;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pagiText{
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 6px;
  padding-bottom: 3px;
  font-family: Roboto;
font-size: 13px;
font-weight: 400;
line-height: 14.06px;
text-align: center;
color: rgba(151, 151, 155, 1);

}
.spacetable{
  border-top: 1px solid rgba(207, 207, 207, 0.767) ;
  width: 1px solid rgba(207, 207, 207, 0.767) ;
  height: 38px;


}





@media (max-width:992px){
  .graphMain{
    flex-direction: column;
}
.tableMain{
  flex-direction: column;
}
}

@media (max-width:992px){
  .cardsMain{
    display: flex;
    flex-direction: column;
  }
}

