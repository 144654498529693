.supRegisterContainer{
    background-image:url("../../Images/supRegBg.png") ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}
.supRegisterContainer2{
width: 442px;
margin-left: auto;
margin-right: auto;
padding-top: 30px;
padding-bottom: 40px;
}
.registerMainHeading{
    display:flex ;
    justify-content: center;
    gap: 16px;
    align-items: center;
    font-family: Roboto Slab;
    font-size: 32px;
    font-weight: 700;
    line-height: 42.2px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 10px;

}
.supRegStepMain{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;

}

.radioMaindiv{
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 20px;


}
.radioMaindiv2{
    padding-top: 20px;
    display: flex;
    gap: 20px;


}
.radioText{
    font-family: Roboto;
font-size: 15px;
font-weight: 500;
line-height: 17.58px;
text-align: left;
color: rgba(255, 255, 255, 1);

}
.radioText3{
    padding-top: 3px;
    font-family: Roboto;
font-size: 15px;
font-weight: 500;
line-height: 17.58px;
text-align: left;
color: rgba(255, 255, 255, 1);

}
.radioText2{
    padding-top: 3px;

    font-family: Roboto;
font-size: 15px;
font-weight: 800;
line-height: 17.58px;
text-align: left;
color: rgba(255, 255, 255, 1);

}
.radioTextBtn{
    display: flex;
    gap: 4px;
    align-items: center;
}

.registerInputMain{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
    font-family: Roboto;
font-size: 15px;
font-weight: 500;
line-height: 17.58px;
text-align: left;
color: rgba(255, 255, 255, 1);

}
.registerInputboth{
    display: flex;
    gap: 10px;
   

}
input::placeholder{
    color: rgba(189, 189, 189, 1);
}
.inputRegister{
    width: 442px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 17.58px;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    color: rgba(74, 79, 84, 1);



}
.regDocmainText2{
    padding-top: 20px;
    font-family: Roboto;
font-size: 15px;
font-weight: 500;
line-height: 17.58px;
text-align: left;
color: rgba(255, 255, 255, 1);
}

.fileUploadContainer{
    display: flex;
    padding-top: 20px;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    width: 445px;

}
.fileName{
    padding-left: 8px;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 11px;
    font-weight: 500;
    line-height: 12.89px;
    text-align: left;
    
    color: rgba(189, 189, 189, 1);
    width: 420px;
    height: 29px;
    background: rgba(255, 255, 255, 1);

}
.customFileUpload{
    padding-left: 5px;
    display: flex;
    align-items: center;
    width: 107px;
height: 30.06px;
background: rgba(2, 86, 36, 1);
border: 1.5px solid rgba(255, 255, 255, 1);
font-family: Russo One;
font-size: 16px;
font-weight: 400;
line-height: 19.28px;
text-align: center;
color: rgba(255, 255, 255, 1);
cursor: pointer;

}
.customFileUpload:hover{
    background: rgb(74, 79, 84, 1);

}

.RegBtnIcnMain{ 
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.RegBtnIcn2{
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 157px;
    height: 50px;
    border-radius: 9px;
    background: rgba(2, 86, 36, 1);
    border: 3px solid rgba(255, 255, 255, 1);
    font-family: Russo One;
font-size: 16px;
font-weight: 400;
line-height: 19.28px;
text-align: center;
color: rgba(255, 255, 255, 1);
    
}
.RegBtnIcn2 span{
    padding-left: 10px;
}
.RegBtnIcn2:hover{
    background: rgba(74, 79, 84, 1);
}



@media(max-width:768px){
    .registerMainHeading{
        gap: 10px;
        font-size: 28px;
    }
    .supRegisterContainer2 {
        width: 90%;
    }

    .fileUploadContainer {
        width: 100%;
        
    }
    .fileName {
        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .customFileUpload {
        height: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-right: 5px;
        font-size: 13px;
    }
    .inputRegister{
        width: 100%;
     
    }
    .radioMaindiv{
        flex-wrap: wrap;
    }
    .radioMaindiv2{
        flex-wrap: wrap;
    }
}
@media(max-width:500px){
    .registerMainHeading {
    
        gap: 14px;
     
        font-size: 27px;
        line-height: 32px;
    }
    .regIcn{
        width: 45px;

    }
}



  
  .errorMessage {
    color: red;
  }