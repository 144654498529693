.homeContainer {
  background-image: url("../../Images/homebg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(22, 22, 22, 0.25);
  background-blend-mode: overlay;
  background-position: center;

}
.homeContainer2 {
  background-image: url("../../Images/homebg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(22, 22, 22, 0.25);
  background-blend-mode: overlay;
}
.homeHeadingSection1Main {
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 30px;
  justify-content: space-between;
}
.homeHeadingSection3Main {
  display: flex;
  align-items: flex-start;
  padding-top: 30px;
  padding-left: 30px;
  justify-content: space-between;
}
.hometextBtnMain {
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.homeMainHeading {
  padding-top: 100px;

  font-family: Roboto Slab;
  font-size: 32px;
  font-weight: 600;
  line-height: 42.2px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.home2Img {
  padding-top: 50px;
}
.homeHeadingSection2Main {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.homeHeadingSection4Main {
  margin-top: -30px;
}

.homebtn2 {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 157px;
  height: 50px;
  padding-left: 14px;
  background: rgba(2, 86, 36, 1);
  border: 3px solid rgba(255, 255, 255, 1);
  border-radius: 9px;
  font-family: Russo One;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.28px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

/* Work csss  */

.homeSection2Main {
  background-image: url("../../Images/workBg.png");
  background-repeat: no-repeat;
  background-position: top;
  height: 1900px;
  /* background-size: 100% ; */
}
.homeSection2Head {
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-items: flex-start; */
  /* justify-content: flex-end; */
}
.workMainHeading {
  padding-top: 190px;
  display: flex;
  gap: 16px;
  font-family: Roboto Slab;
  font-size: 36px;
  font-weight: 700;
  line-height: 47.48px;
  text-align: left;

  color: rgba(113, 168, 80, 1);
}
.workSectionMain {
  padding-top: 140px;
  padding-bottom: 40px;
  /* margin-top: -120px; */
  width: 74%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Wraps items into new rows */
  grid-gap: 10px;
}
.workSection {
  display: inline-grid;
  justify-content: center;
  padding-bottom: 70px;
}
.logo1 {
  padding-left: 28px;
}
.workSectionHeading {
  padding-top: 20px;
  font-family: Roboto Slab;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.65px;
  text-align: center;
  color: rgba(113, 168, 80, 1);
}
.workSectionText {
  padding-top: 10px;

  margin-left: auto;
  margin-right: auto;
  width: 176px;
  font-family: Roboto Slab;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.46px;
  text-align: center;
  color: rgba(74, 79, 84, 1);
}
.workSection2Main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.Work5Img {
  padding-top: 60px;
}

/* Section3 css  */

.section3Container {
  background-image: url("../../Images/Transparencia.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(74, 79, 84, 0.3);
  background-blend-mode: overlay;
  height: 395px;
}
.section3Heading {
  padding-top: 50px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  font-family: Roboto Slab;
  font-size: 36px;
  font-weight: 700;
  line-height: 47.48px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.section3Text {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  padding-top: 50px;
  padding-bottom: 80px;
}

@media (max-width: 837.5px) {
  .homeSection2Main {
    background-image: url("../../Images/workBg.png");
    background-repeat: no-repeat;
    background-position: top;
    height: auto;
  }
}
@media (max-width: 768px) {
  .workMainHeading {
    padding-top: 50px;
    gap: 10px;
    font-size: 32px;
  }
  .workSectionMain {
    padding-top: 80px;
    padding-bottom: 10px;
    width: 90%;
  }
  .workSection {
    display: inline-grid;
    justify-content: center;
    padding-bottom: 40px;
  }
}
@media (max-width: 550px) {
  .Work2Img {
    width: 50px;
  }
  .workMainHeading {
    padding-top: 50px;
    gap: 10px;
    font-size: 28px;
  }
  .logo1 {
    padding-left: 45px;
    width: 130px;
  }
}

@media (max-width: 1342px) {
  .homeImg {
    width: 300px;
  }
  .home2Img {
    width: 180px;
  }
  .home3Img {
    width: 280px;
  }
  .home4Img {
    width: 280px;
  }
  .homeHeadingSection1Main {
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
    /* flex-direction: column; */
  }
}
@media (max-width: 992px) {
  .homeImg {
    width: 250px;
  }
  .homeMainHeading {
    padding-top: 70px;
    font-size: 26px;
    line-height: 38.2px;
  }
  .home2Img {
    width: 120px;
  }
  .home3Img {
    width: 220px;
  }
  .home4Img {
    width: 220px;
  }
  .homeHeadingSection1Main {
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
  }
}
@media (max-width: 768px) {
  .homeImg {
    width: 190px;
  }
  .homeMainHeading {
    padding-top: 70px;
    font-size: 24px;
    line-height: 38.2px;
  }
  .home2Img {
    width: 100px;
  }
  .home3Img {
    width: 160px;
  }
  .home4Img {
    width: 160px;
  }
}
@media (max-width: 530px) {
  .homeContainer {
    height: 100vh;
  }
  .homeContainer2 {
    height: 100vh;
  }
  .homeImg {
    width: 0px;
    display: none;
  }
  .home4Img {
    width: 0px;
    display: none;
  }
  .homeMainHeading {
    padding-top: 0px;
    font-size: 26px;
    line-height: 28px;
  }
  .home2Img {
    display: none;

    width: 0px;
  }
  .home3Img {
    width: 0px !important;
    display: none !important;
  }
  .homeHeadingSection1Main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 100vh;
  }
  .homeHeadingSection3Main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 100vh;
  }
  .hometextBtnMain{
    margin-top: 0px;
}
}
@media (max-width: 768px) {
  .section3Heading {
    padding-top: 50px;
    gap: 10px;
    font-size: 30px;
    line-height: 44.48px;
  }
  .sec3Img {
    width: 55px;
  }
  .section3Text {
    font-size: 28px;
    line-height: 34.5px;
  }
}

@media (max-width: 425px) {
  .section3Heading {
    padding-top: 50px;
    gap: 10px;
    font-size: 26px;
    line-height: 40.48px;
  }
  .sec3Img {
    width: 40px;
  }
  .section3Text {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 24px;
    line-height: 32.5px;
  }
}
