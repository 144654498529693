.constrContainer{
    height: 100vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contIcon{
    width: 100px;
    height: 100px;
}
.contrHeadingMain{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Roboto Slab;
font-size: 36px;
font-weight: 700;
line-height: 47.48px;
text-align: center;
color: rgba(113, 168, 80, 1);

}