.registerContainer {
    background-image: url("../../Images/regbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    min-height: 100vh;
}

.navBtn {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: center;
    width: 178px;
    height: 61px;
    border: 3px solid rgba(255, 255, 255, 1);
    font-family: Russo One;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.28px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    background: rgba(113, 168, 80, 1);
    border-radius: 8px;
}

.navBtn:hover {
    background: rgba(2, 86, 36, 1);
}

.navBtnIcn {
    width: 26px;
}

.registerContainer2 {

    display: flex;
    justify-content: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 180px;
    gap: 100px;
    padding-bottom: 180px;
}

.registerLeftMain {
    width: 442px;


}

.registerRightMain {
    width: 442px;

}

.registerMainHeading {
    justify-content: center;
    display: flex;
    gap: 20px;
    align-items: center;
    font-family: Roboto Slab;
    font-size: 32px;
    font-weight: 700;
    line-height: 42.2px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 10px;

}

.registerInputMain {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 40px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.58px;
    text-align: left;
    color: rgba(255, 255, 255, 1);

}

.inputRegister {
    width: 442px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 17.58px;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    color: rgba(74, 79, 84, 1);



}

.regStepMain {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.showPasswordInput {
    display: flex;
    align-items: center;
    width: 442px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 17.58px;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    color: rgba(74, 79, 84, 1);
}

.passwordinput {
    width: 410px;
    height: 40px;
    border: 0;

}

.forgoText {
    padding-top: 12px;
    cursor: pointer;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 700;
    line-height: 17.58px;
    text-align: center;
    color: rgba(2, 86, 36, 1);
    text-decoration: underline;

}

.registerCheckbox {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.06px;
    text-align: left;
    color: rgba(255, 255, 255, 1);

}

.registerCheckbox span {
    color: rgba(2, 86, 36, 1);
    text-decoration: underline;
    cursor: pointer;

}

.RegBtnIcnMain {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.RegBtnIcn {
    display: flex;
    gap: 22px;
    align-items: center;
    padding-left: 10px;
    width: 157px;
    height: 50px;
    border-radius: 9px;
    background: rgba(2, 86, 36, 1);
    border: 3px solid rgba(255, 255, 255, 1);
    font-family: Russo One;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.28px;
    text-align: center;
    color: rgba(255, 255, 255, 1);



}

.RegBtnIcn:hover {
    background: rgba(74, 79, 84, 1);
}

.navBtnIcn {
    width: 26.88px;
}

.RegBtnIcn2 {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 157px;
    height: 50px;
    border-radius: 9px;
    background: rgba(2, 86, 36, 1);
    border: 3px solid rgba(255, 255, 255, 1);
    font-family: Russo One;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.28px;
    text-align: center;
    color: rgba(255, 255, 255, 1);

}

.RegBtnIcn2 span {
    padding-left: 10px;
}

.RegBtnIcn2:hover {
    background: rgba(74, 79, 84, 1);
}




@media (max-width:1000px) {
    .registerLeftMain {
        width: 100%;


    }

    .registerRightMain {
        width: 100%;

    }

    .registerContainer2 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 90px;
        gap: 100px;
        padding-bottom: 90px;
    }

    .inputRegister {
        width: 100%;
    }

    .showPasswordInput {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

    }

    .passwordinput {
        width: 100%;
    }
}

@media(max-width:500px) {
    .registerContainer2 {
        width: 90%;
        padding-top: 40px;
        gap: 70px;
        padding-bottom: 40px;
    }

    .registerMainHeading {

        gap: 14px;

        font-size: 27px;
    }

    .regIcn {
        width: 45px;

    }
}

@media(max-width:400px) {

    .registerMainHeading {
        gap: 14px;
        font-size: 24px;
    }

    .regIcn {
        width: 45px;

    }
}











/* .invalidInput {
    border: 1px solid red;
  } */
.registerLink {
    text-decoration: underline;

}

.errorMessage {
    color: red;
    font-size: 0.9rem;
    margin-top: 4px;
}