/* ReportPage.module.css */
.scoreReportMain{
  padding: 10px;
  background-color: #F1F1F1;
  
}
.scoreContainer {
  padding: 10px;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  }
  .reportHeadingMain {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.13px;
    text-align: left;
    color: rgba(88, 88, 87, 1);
    margin-bottom: 20px;
  
    
  }
  .cardsMain{
    display: flex;
    gap: 10px;
    width: 100%;
    margin-top: 20px;
  }
  .cardsContainer{
    background-color:rgb(52,152,219) ;
    align-items: flex-end;
    width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-bottom: 10px;
  padding-right: 20px;
  height: 160px;
  }
  .cardsContainer2{
    background-color:rgb(52,183,95) ;
    align-items: flex-end;
    width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  height: 160px;
  
  }
  .cardsContainer3{
    background-color:rgb(225,77,67) ;
    align-items: flex-end;
    width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  height: 160px;
  
  }
  .cardsContainer4{
    background-color: rgb(132,97,181);
    align-items: flex-end;
    width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  height: 160px;
  
  }
  .rankIcn{
    width: 110px; 
    height: 110px;
    color: rgb(93,173,226);
  }
  .ArrowUpIcn{
    width: 80px; 
    height: 80px;
    color: rgb(93,197,127);
  }
  .ArrowDownIcn{
    width: 80px; 
    height: 80px;
    color: rgb(231,113,105);
  }
  .reloadIcn{
    width: 80px; 
    height: 80px;
    color: rgb(157,129,196);
  }
  .cardText1{
    font-family: Roboto;
  font-size: 38px;
  font-weight: 600;
  line-height: 18.75px;
  text-align: right;
  color: #FFFF;
  padding-bottom: 20px;
  }
  .cardText2{
    font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.75px;
  text-align: center;
  color: rgba(133, 202, 248, 1);
  padding-bottom: 20px;
  
  
  }
  .cardText3{
    font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.75px;
  text-align: center;
  color: rgba(130, 231, 163, 1);
  padding-bottom: 10px;
  
  
  }
  .cardText4{
    padding-bottom: 10px;
    font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.75px;
  text-align: center;
  color: rgba(249, 165, 159, 1);
  
  
  }
  .cardText5{
    padding-bottom: 10px;
    font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.75px;
  text-align: center;
  color: rgba(157, 129, 196, 1);
  
  
  }
  







  .tableMain1{
    width: 100%;
  margin-top: 20px;
  background-color: #FFFF;

  }
  .tableMain2{
    width: 100%;
    min-width: 500px;
  

  }
    .tableContainer{
      width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
    }

  .tableHeadings{
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.23px;
    text-align: center;
    
  color: rgba(88, 88, 87, 1);
  
  }
  

  .tableTextTwo{
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.23px;
    text-align: center;
    
    color: rgba(151, 151, 155, 1) !important;
  
  }
  .tableMain1 table th{
    padding: 10px;
    text-align: center;
    border-left: 0px;
    border-right: 0px;
  }
  .tableMain1 table td {
    padding: 10px;
    text-align: center;
    border: 0px;
  }

  .spacetable{
    border-bottom:1px solid rgba(207, 207, 207, 0.767)  ;
    border-top:1px solid rgba(207, 207, 207, 0.767)  ;
  height: 38px;
  
  }






  
  @media (max-width:1200px){
    .scoreContainer {
      padding: 10px;
      width: 94%;
      }
  }
  
  @media (max-width:500px){
    .scoreContainer {
      padding: 10px;
      width: 100%;
      }
  }
  @media (max-width:992px){
    .cardsMain{
      display: flex;
      flex-direction: column;
    }
  }