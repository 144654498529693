@font-face {
  font-family: "Russo One";
  src: url("../public/fonts/Russo_One/RussoOne-Regular.ttf");
}

.roboto-slab {
  font-family: 'Roboto Slab', serif;
}

input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
textarea:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
select:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
.slick-dots {
  display: none !important;
}
body,
html {
  overflow-x: hidden;
}

/* Hide the default checkbox */
.custom-checkbox input {
  display: none;
}

/* Style the checkmark (the box) */
.custom-checkbox .checkmark {
  margin-right: 8px;
  width: 14px;
  height: 14px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(199, 199, 205, 1);
  display: inline-block;
  border-radius: 0px;
  position: relative;
  cursor: pointer;
}

/* Change background color when the checkbox is checked */
.custom-checkbox input:checked + .checkmark {
  background-color: rgba(2, 86, 36, 1);
}

/* Optional: Add a checkmark icon inside the box when checked */
.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked + .checkmark::after {
  display: block;
  left: 4px;
  top: 0.5px;
  width: 4.5px;
  height: 8.5px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* For Radio css  */

/* Hide the default radio button */
.custom-radio input {
  display: none;
}

/* Style the checkmark (the circle) */
.custom-radio .checkmark {
  margin-right: 8px;
  width: 14px;
  height: 14px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(199, 199, 205, 1);
  display: inline-block;
  border-radius: 50%; /* Circular shape for radio */
  position: relative;
  cursor: pointer;
}

/* Change to green background when the radio button is selected */
.custom-radio input:checked + .checkmark {
  background-color: white; /* Green color */
  border: 1px solid rgba(2, 86, 36, 1);
}

/* Optional: Add a dot inside the circle when checked */
.custom-radio .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked + .checkmark::after {
  display: block;
  left: 1px;
  top: 1px;
  width: 10px;
  height: 10px;
  background-color: rgba(2, 86, 36, 1);
  border-radius: 50%; /* Small circle for radio indicator */
}
