.footerContainer{
    background: rgba(2, 86, 36, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 16px;
  }
  .footerText{
    flex-shrink: 0;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }
  .footerText span{
text-decoration: underline;
}

@media (max-width:640px) {
    .footerText{
    font-size: 8px; 
    }  
  }
  
  @media (max-width:400px) {
  .footerText{
  font-size: 7px; 
  }  
}