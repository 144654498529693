.justify-content-end {
  justify-content: center !important;
}
.bg-body-tertiary {
  background-color: rgba(113, 168, 80, 1) !important;
}
.navbar {
  --bs-navbar-nav-link-padding-x: 1.5rem !important;
}
.nav-link {
  padding-top: 26px;
  font-family: Roboto Slab;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.38px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.nav-link:hover {

  color:rgba(74, 79, 84, 1);
}
.mb-3 {
  margin-bottom: 0rem !important;
}

.offcanvas-body {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.navbar-nav {
  flex-wrap: wrap;
}

@media (max-width: 1112px) {
  .navbar {
    --bs-navbar-nav-link-padding-x: 1.4rem !important;
  }
}
@media (max-width: 1282px) {
  .nav-link {
    padding-top: 28px;
    font-size: 18px;
    line-height: 24.38px;
  }
}
@media (max-width: 1100px) {
  .nav-link {
    padding-top: 26px;
    font-size: 15.2px;
    line-height: 24.38px;
  }
}

.navbar-toggler-icon {
  margin-top: 16px;
  width: 1.4em;
  height: 1em;
  filter: invert(1); /* This will invert the color to white */
}
.navbar-toggler {
  border: none !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}

@media (max-width: 991.5px) {
  .offcanvas-body {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-direction: column;
    gap: 40px !important;
  }
  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 22px;
    color: rgb(0, 0, 0);
  }
  .navbar-nav {
    justify-content: flex-start !important;
  }
  .flex-grow-1 {
    flex-grow: 0 !important;
  }
  .align-self-center {
    align-self: flex-start !important;
}



}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 1rem;
 
}

/* Styles for active link with background image */
.active-link {

  position: relative;
  background-image: url('../../Images/activeLink.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position:0px 26px;
  color:#4b4f54 !important;
  font-weight: bold;

}
.active-link2 {

  position: relative;
  background-image: url('../../Images/activeLink.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position:0px 20px;
  color:#4b4f54 !important;
  font-weight: bold;

}
.active-link3 {

  position: relative;
  background-image: url('../../Images/activeLink.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position:0px 15px;
  color:#4b4f54 !important;
  font-weight: bold;

}
.active-link4 {

  position: relative;
  background-image: url('../../Images/activeLink.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position:0px 22px;
  color:#4b4f54 !important;
  font-weight: bold;

}
.active-link5 {

  background: rgba(2, 86, 36, 1);

}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color:rgba(74, 79, 84, 1);

}

@media (max-width:991.5px){
  .active-link {

    background-position:0px 22px;
  
  }
  .active-link2 {
  
    background-position:0px 22px;
  
  }
  .active-link3 {
  
    background-position:0px 22px;
    color:rgba(74, 79, 84, 1);
  
  }
  .active-link4 {
  
    background-position:0px 22px;
  
  }
  
}