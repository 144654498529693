.termsContainer{
background-image: url("../../Images/termbg.png");
background-position: top;
background-size: cover;
background-repeat: no-repeat;
}
.termsMainHeading{
    padding-top: 30px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    font-family: Roboto Slab;
font-size: 32px;
font-weight: 700;
line-height: 42.2px;
text-align: left;
color: rgba(113, 168, 80, 1);

}
.TermIcnImg{
    width: 49.96px;
}
.termsHeadingText{
    padding-top: 30px;
    padding-bottom: 30px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto;
font-size: 15px;
font-weight: 400;
line-height: 17.58px;
text-align: center;
color: rgba(74, 79, 84, 1);

}
.termsHeadingText span{
    color: rgba(0, 0, 0, 1);
    font-weight: 700;


}


@media (max-width:768px) {
    .termsMainHeading{
        padding-top: 30px;
    font-size: 30px;
    
    }
    .termsHeadingText{
        width: 90%;
    font-size: 15px;
    
    }
    
}
@media (max-width:425px) {
    .termsMainHeading{
        padding-top: 30px;
    font-size: 22px;
    gap: 10px;
    
    }
    .termsHeadingText{
        width: 94%;
    font-size: 15px;
    
    }
    .TermIcnImg{
        width: 40px;
    }
    
}