.header1Main {
  display: flex;
  padding-left: 120px;
  padding-right: 48px;
  justify-content: space-between;
  background-color: rgba(74, 79, 84, 1);
  padding-top: 6px;
  padding-bottom: 6px;
  gap:32px;
  flex-wrap: nowrap;
  align-items: center;
}
.logoImg {
  width: 320px;
}
.header1Container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 64px;
  flex-wrap: wrap;
}
.detailMain {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.detailText {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 10.55px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  flex-shrink: 0;
}
.icon{
  width: 12px;
}
.header1Container2 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.NavButtonsMain {
  display: flex;
  gap: 20px;
}
.navBtn {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  width: 178px;
  height: 61px;
  border: 3px solid rgba(255, 255, 255, 1);
  font-family: Russo One;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.28px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  background: rgba(113, 168, 80, 1);
  border-radius: 8px;
}
.navBtn:hover {
  background: rgba(2, 86, 36, 1);
}

.active-link5 {

  background: rgba(2, 86, 36, 1);

}
.navBtn2:hover {
  background: rgba(2, 86, 36, 1);
}
.navBtn2 {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 178px;
  height: 61px;
  border: 3px solid rgba(255, 255, 255, 1);
  font-family: Russo One;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.28px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  background: rgba(113, 168, 80, 1);
  border-radius: 8px;
}

@media (max-width: 1282px) {
  .logoImg {
    width: 280px;
  }
  .NavButtonsMain {
    display: flex;
    gap: 10px;
  }
  .navBtn2 {
    width: 132px;
    height: 52px;
    font-size: 14px;
    line-height: 18.28px;
    gap: 8px;
  }
  .navBtn {
    gap: 24px;
    width: 132px;
    height: 52px;
    font-size: 14px;
    line-height: 18.28px;
  }
  .navBtnIcn {
    width: 26px;
  }
  .navBtnIcn2 {
    width: 36px;
  }
}
@media (max-width: 1250px) {
  .header1Main {
    padding-left: 20px;
    padding-right: 20px;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center; 
    /* align-items: center;*/
  }
  .header1Container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }
}
@media (max-width: 1100px) {
  .logoImg {
    width: 220px;
  }
}
@media (max-width: 771px) {
  .header1Main {
    /* gap: 32px; */
  }
  .detailText {
    width: 200px;
  }
}
@media (max-width: 425px) {
  .logoImg {
    width: 220px;
  }
}
