.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: rgba(241, 241, 241, 1) !important;
    border-color: 1px solid rgba(139, 139, 139, 0.468) !important;
}
.nav-tabs .nav-link{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    border-radius: 0 !important;
    border: 1px solid rgba(139, 139, 139, 0.468);
    font-family: Roboto !important;
font-size: 11px !important;
font-weight: 600 !important;
line-height: 12.89px !important;
text-align: center !important;
color: rgba(88, 88, 87, 1) !important;
background: rgba(229, 229, 229,1) !important;
margin-left: 6px !important;
}
.nav-tabs{
    --bs-border-color: rgba(139, 139, 139, 0.468) !important;
}


.page-link {
    color:  rgba(151, 151, 155, 1) !important;
    border-radius: 0 !important;
 
  }