.regDocContainer {
    background-image: url("../../Images/regDocBg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.regDocContainer2 {
    width: 432px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
}

.registerMainHeading {
    display: flex;
    align-items: center;
    font-family: Roboto Slab;
    font-size: 32px;
    font-weight: 700;
    line-height: 42.2px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 10px;

}

.regStepMain {
    padding-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.regDocmainText {
    padding-top: 10px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
    color: rgba(255, 255, 255, 1);

}

.RegBtnIcnMain {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.RegBtnIcn {
    display: flex;
    gap: 12px;
    align-items: center;
    padding-left: 10px;
    width: 157px;
    height: 50px;
    border-radius: 9px;
    background: rgba(2, 86, 36, 1);
    border: 3px solid rgba(255, 255, 255, 1);
    font-family: Russo One;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.28px;
    text-align: center;
    color: rgba(255, 255, 255, 1);



}

.RegBtnIcn:hover {
    background: rgba(74, 79, 84, 1);
}

.regDocmainText2 {
    padding-top: 20px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.58px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.regDocmainTextBg {
    margin-top: 10px;
    padding-left: 8px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.58px;
    text-align: left;
    color: rgba(74, 79, 84, 1);
    width: 385px;
    height: 29px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;

}

.fileUploadContainer {
    display: flex;
    padding-top: 20px;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    width: 485px;

}

.fileName {
    padding-left: 8px;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.58px;
    text-align: left;
    color: rgba(74, 79, 84, 1);
    width: 420px;
    height: 36px;
    background: rgba(255, 255, 255, 1);

}

.fileUploadDialog {
    background: rgb(219, 218, 218) !important;
}

.customFileUpload {
    padding-left: 5px;
    display: flex;
    align-items: center;
    width: 107px;
    height: 36.06px;
    background: rgba(2, 86, 36, 1);
    border: 1.5px solid rgba(255, 255, 255, 1);
    font-family: Russo One;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.28px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;

}

.customFileUpload:hover {
    background: rgb(74, 79, 84, 1);

}

.RegBtnIcn2 {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 157px;
    height: 50px;
    border-radius: 9px;
    background: rgba(2, 86, 36, 1);
    border: 3px solid rgba(255, 255, 255, 1);
    font-family: Russo One;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.28px;
    text-align: center;
    color: rgba(255, 255, 255, 1);

}

.RegBtnIcn2 span {
    padding-left: 10px;
}

.RegBtnIcn2:hover {
    background: rgba(74, 79, 84, 1);
}


@media(max-width:768px) {
    .registerMainHeading {
        gap: 10px;
        font-size: 28px;
    }

    .regDocContainer2 {
        width: 90%;
    }

    .regDocmainTextBg {

        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;



    }

    .fileUploadContainer {
        width: 100%;

    }

    .fileName {
        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .customFileUpload {
        height: 100%;
        padding-top: 8px;
        padding-bottom: 7.5px;
        padding-right: 5px;
        font-size: 13px;
    }
}

@media(max-width:500px) {
    .registerMainHeading {

        gap: 14px;

        font-size: 27px;
        line-height: 32px;
    }

    .regIcn {
        width: 45px;

    }
}

.errorMessage {
    color: red;


}