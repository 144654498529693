.registerGouContainer {
  background-image: url("../../Images/workBg.png");
  background-repeat: no-repeat;
  background-position: top;
}

.registerGouContainer2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
}

.homeSection2Head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.workMainHeading {
  /* padding-top: 220px; */
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: Roboto Slab;
  font-size: 36px;
  font-weight: 700;
  line-height: 47.48px;
  text-align: left;

  color: rgba(113, 168, 80, 1);
}

.registerGouSection2Main {
  margin-top: 40px;
  width: 1014px;
  padding: 100px;
  background: rgba(240, 240, 241, 1);
  border: 1px solid rgba(233, 233, 238, 1);
  border-radius: 22px;
}

.registerInputMain2 {
  display: flex;
  padding-top: 15px;
  width: 100%;
  gap: 20px;
}

.inputRegisterLeft {
  width: 100%;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(74, 79, 84, 1);
}

.inputRegisterRight {
  width: 100%;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(74, 79, 84, 1);
}

.inputRegister2 {
  margin-top: 30px;
  width: 95%;
  height: 74px;
  border: 1px solid rgba(74, 79, 84, 1);
  background: rgba(255, 255, 255, 1);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(74, 79, 84, 1);
}

.bailInput {
  height: 60px;
  border: 1px solid rgba(74, 79, 84, 1);
  background: rgba(255, 255, 255, 1);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(74, 79, 84, 1);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: 50px;
  width: 50px;
}

.referenceInput {
  height: 40px !important;
}

.regDocmainText {
  padding-top: 30px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: #4a4f54;
}

.secondaryLable {
  padding-top: 20px;
  font-family: Roboto;
  font-size: 15px;
  /* font-weight: 500; */
  /* line-height: 18.75px; */
  text-align: left;
  color: #4a4f54;
}

.registerTextarea {
  margin-top: 20px;
  width: 98%;
  height: 421px;
  padding-top: 8px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(74, 79, 84, 1);

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 8px;
  padding-right: 5px;
  color: rgba(74, 79, 84, 1);
}

.registerTextarea2 {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 98%;
  height: 257px;
  padding-top: 8px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(74, 79, 84, 1);

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 8px;
  padding-right: 5px;
  color: rgba(74, 79, 84, 1);
}

.registerTextarea3 {
  margin-top: 20px;
  width: 98%;
  height: 151px;
  padding-top: 8px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(74, 79, 84, 1);

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 8px;
  padding-right: 5px;
  color: rgba(74, 79, 84, 1);
}

.selecterMain {
  display: flex;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

.selecterInput {
  width: 228.73px;
  height: 73.19px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(74, 79, 84, 1);
  border: 1px solid rgba(74, 79, 84, 1);
}

.selecterInput2 {
  width: 263.5px;
  height: 73.19px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(74, 79, 84, 1);

  border: 1px solid rgba(74, 79, 84, 1);
}

.registerInputMain {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(74, 79, 84, 1);
}

.inputRegister {
  margin-top: 10px;
  width: 98%;
  height: 74px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(74, 79, 84, 1);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(74, 79, 84, 1);
}

.RegBtnIcnMain {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RegBtnIcn2 {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 157px;
  height: 50px;
  border-radius: 9px;
  background: rgba(2, 86, 36, 1);
  border: 3px solid rgba(255, 255, 255, 1);
  font-family: Russo One;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.28px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.RegBtnIcn2 span {
  padding-left: 10px;
}

.RegBtnIcn2:hover {
  background: rgba(74, 79, 84, 1);
}

.fileUploadContainer {
  display: flex;
  padding-top: 20px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;

}

.fileName {
  padding-left: 8px;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  border: 1px solid rgba(74, 79, 84, 1);
  color: rgba(189, 189, 189, 1);
  width: 74%;
  height: 74px;
  background: rgba(255, 255, 255, 1);

}

.customFileUpload {
  padding-left: 0px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 75px;
  background: #025624;
  border: 1.5px solid rgba(255, 255, 255, 1);
  font-family: Russo One;
  font-size: 22px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;

}

.fileupIcn {
  width: 22px;
  height: 22px;
}

.customFileUpload:hover {
  background: rgb(74, 79, 84, 1);

}

@media (max-width: 1030px) {
  .registerGouContainer2 {
    width: 90%;
  }

  .registerGouSection2Main {
    margin-top: 40px;
    width: 100%;
    padding: 100px;
  }

  .selecterMain {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .registerGouSection2Main {
    margin-top: 40px;
    width: 100%;
    padding: 20px;
  }

  .registerMainHeading {
    gap: 10px;
    font-size: 28px;
  }

  .inputRegister2 {
    width: 95%;
    height: 50px;
  }

  .inputRegister {
    width: 95%;
    height: 50px;
  }

  .selecterInput2 {
    height: 50px;
    font-size: 14px;
  }

  .regDocmainText {
    font-size: 14px;
  }

  .inputRegisterRight {
    font-size: 14px;
  }

  .registerInputMain {
    font-size: 14px;
  }

  .selecterInput {
    height: 50px;
    font-size: 14px;
  }

  .inputRegisterLeft {
    font-size: 14px;
  }

  .inputRegisterLeft2 {
    width: 96px;
  }

  .inputRegister {
    width: 100%;
  }

  .inputRegister3 {
    width: 100%;
  }

  .inputRegister4 {
    width: 100%;
  }

  .registerInputMain {
    display: flex;
    gap: 20px;
  }

  .registerInputMain2 {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .registerTextarea {
    width: 100%;
  }

  .registerTextarea2 {
    width: 100%;
  }

  .fileUploadContainer {
    width: 100%;
    padding-right: 0px;

  }

  .fileName {
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;

  }

  .customFileUpload {
    width: 200px;
    height: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 5px;
    font-size: 15px;
    gap: 8px;

  }

  .fileupIcn {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 500px) {
  .workMainHeading {
    gap: 14px;
    padding-top: 40px;
    font-size: 27px;
    line-height: 32px;
  }

  .Work2Img {
    width: 45px;
  }

  .registerGouSection2Main {
    margin-top: 40px;
    padding: 10px;
  }
}

.errorMessage {
  margin-top: 5px;
  color: red;
  font-weight: normal;
}