/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.projectRegisterContainer2 {
  width: 442px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 40px;
}

.mainProjectFields {
  width: 360px;
  display: flex;
  gap: 32px;
}

.registerMainHeading {
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  font-family: Roboto Slab;
  font-size: 32px;
  font-weight: 700;
  line-height: 42.2px;
  text-align: center;
  color: rgba(2, 86, 36, 1);

  padding-bottom: 10px;
}

.supRegStepMain {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.registerInputMain {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(2, 86, 36, 1);


}

.inputRegister {
  width: 442px;
  height: 40px;
  background: #e5e7e6;

  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(2, 86, 36, 1);
}

.radioMaindiv {
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 17px;


}

.radioText {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(2, 86, 36, 1);


}


.radioTextBtn {
  display: flex;
  gap: 4px;
  align-items: center;
}

.regDocmainText {
  padding-top: 10px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: rgba(2, 86, 36, 1);


}

.registerTextarea {
  margin-top: 10px;
  width: 442px;
  height: 230px;
  padding-top: 8px;
  background: rgba(229, 231, 230, 1);

  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 8px;
  padding-right: 5px;
  color: rgba(2, 86, 36, 1);




}

.registerTextarea2 {
  margin-top: 10px;
  width: 442px;
  height: 141px;
  padding-top: 8px;
  background: rgba(229, 231, 230, 1);

  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 8px;
  padding-right: 5px;
  color: rgba(2, 86, 36, 1);




}

.registerInputMain2 {
  display: flex;
  padding-top: 15px;
  width: 100%;
  gap: 20px;
}

.inputRegisterLeft {
  width: 100%;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(2, 86, 36, 1);

}

.inputRegisterRight {
  width: 100%;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(2, 86, 36, 1);

}

.inputRegister2 {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  background: rgba(229, 231, 230, 1);

  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(2, 86, 36, 1);

}

.selecterMain {
  display: flex;
  gap: 14px;
  padding-top: 10px;
  width: 100%;
}

.selecterInput {
  width: 125px;
  height: 40px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(88, 88, 87, 1);


  background: rgba(229, 231, 230, 1);


}

.selecterInput2 {
  width: 144px;
  height: 40px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(88, 88, 87, 1);

  background: rgba(229, 231, 230, 1);

}

.showPasswordInput {
  display: flex;
  align-items: center;
  width: 442px;
  height: 40px;
  background: rgba(229, 231, 230, 1);

  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(2, 86, 36, 1);

}

.passwordinput {
  width: 410px;
  height: 40px;
  border: 0;
  background: rgba(229, 231, 230, 1);

  color: rgba(2, 86, 36, 1);
}

.hideIcn {
  background-image: url("../../../Images/hideIcn.png");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;

}

.fileUploadContainer {
  display: flex;
  padding-top: 20px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  width: 445px;

}

.fileName {
  padding-left: 8px;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  line-height: 12.89px;
  text-align: left;

  color: rgba(2, 86, 36, 1);
  width: 420px;
  height: 29px;
  background: rgba(229, 231, 230, 1);


}

.customFileUpload {
  padding-left: 5px;
  display: flex;
  align-items: center;
  width: 107px;
  height: 30.06px;
  background: rgba(2, 86, 36, 1);
  border: 1.5px solid rgba(255, 255, 255, 1);
  font-family: Russo One;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.28px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;

}

.customFileUpload:hover {
  background: rgb(74, 79, 84, 1);

}

.RegBtnIcnMain {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.RegBtnIcn2 {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 157px;
  height: 50px;
  border-radius: 9px;
  background: rgba(2, 86, 36, 1);
  border: 3px solid rgba(255, 255, 255, 1);
  font-family: Russo One;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.28px;
  text-align: center;
  color: rgba(255, 255, 255, 1);

}

.RegBtnIcn2 span {
  padding-left: 10px;
}

.RegBtnIcn2:hover {
  background: rgba(74, 79, 84, 1);
}


.rightMainSelector {
  padding-top: 55px;
}

.selectright {
  background: rgba(229, 231, 230, 1);
  width: 266px;
  height: 40px;

}

/* @media (max-width:1400px){
    .mainProjectFields{
        width: 60%;
        gap: 32px;
      }
      .selectright{
        width: 250px;
        height: 40px;
        
    }
  } */
/* @media (max-width:1210px){
    .mainProjectFields{
        width: 75%;
        gap: 32px;
      }
  } */
/* @media (max-width:1105px){
    .mainProjectFields{
        width: 85%;
        gap: 32px;
      }
      .selectright{
        width: 240px;
        height: 40px;
        
    }
  } */
@media (max-width:1034px) {

  /* .projectRegisterContainer2{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 40px;
} */
  /* .mainProjectFields{
        width: 95%;
        gap: 20px;
      }
      .rightMainSelector{
        padding-top: 56px;
        width: 100%;
    }
      .selectright{
        width: 100%;
        height: 40px;
        
    } */
  .inputRegister {
    width: 100%;

  }

  .registerTextarea {
    width: 100%;
    height: 230px;

  }

  .registerTextarea2 {
    width: 100%;

  }

  .fileUploadContainer {
    width: 100%;
  }

  .showPasswordInput {
    width: 100%;
  }

  .passwordinput {
    width: 100%;
  }

  .selecterCont {
    width: 100%;

  }

  /* .selecterInput {
  width: 100%;
  height: 40px;
}
.selecterInput2 {
  width: 100%;
  height: 40px;
} */
}

@media (max-width: 768px) {
  .registerMainHeading {
    gap: 10px;
    font-size: 28px;
  }

  .projectRegisterContainer2 {
    width: 100%;
  }

  .rightMainSelector {
    padding-top: 67px;
    width: 40%;
  }

  .mainProjectFields {
    width: 90%;
    display: flex;
    justify-content: center;
  }

  .fileUploadContainer {
    width: 100%;
  }

  .fileName {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .customFileUpload {
    height: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 5px;
    font-size: 13px;
  }

  .inputRegisterLeft2 {
    width: 96px;
  }

  .inputRegister {
    width: 100%;
  }

  .inputRegister3 {
    width: 100%;
  }

  .inputRegister4 {
    width: 100%;
  }

  .registerInputMain {
    display: flex;
    gap: 20px;
  }

  .registerInputMain2 {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .radioMaindiv {
    flex-wrap: wrap;
  }

  .radioMaindiv2 {
    flex-wrap: wrap;
  }

  .registerTextarea {
    width: 100%;
  }

  .registerTextarea2 {
    width: 100%;
  }

  .showPasswordInput {

    width: 100%;
  }

  .selecterMain {

    flex-wrap: wrap;
  }
}

@media (max-width: 500px) {
  .registerMainHeading {
    gap: 14px;

    font-size: 27px;
    line-height: 32px;
  }

  .regIcn {
    width: 45px;
  }

  .rightMainSelector {
    padding-top: 67px;
    width: 60%;
  }
}

.errorText {
  padding-top: 4px;
  color: red;
  font-weight: 500;
  line-height: 17.58px;
  font-family: Roboto;
  font-size: 15px;
}