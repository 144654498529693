.reportHeadingMain {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.13px;
  text-align: left;
  color: rgba(88, 88, 87, 1);
  margin-bottom: 20px;


}

.reportHeading {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.44px;
  text-align: center;
  color: rgba(88, 88, 87, 1);

}

.userReportMain {
  width: 95%;
  padding: 20px;
  background-color: #F1F1F1;
}

.userReportContainer {
  max-width: 100%;
  margin: 0 auto;
}


.reportSection {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
}

.reportsection1 {
  padding: 30px;
  width: 65%;
  background-color: #FFFFFF;
}

.reportsection2 {
  padding: 30px;
  width: 35%;
  background-color: #FFFFFF;
}

.reportInnerSection {
  padding: 40px;
  background-color: #FFFFFF;
}

.reportSection h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

canvas {
  width: 100% !important;
  /* height: 400px !important; */
}


@media (max-width:1200px) {
  .reportsection1 {
    padding: 20px;
    background-color: #FFFFFF;
    height: 500px;
  }

  .reportInnerSection {
    padding: 20px;
    background-color: #FFFFFF;
  }
}

/* @media (max-width:1000px) {
  .reportSection {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
  }

  .reportsection1 {
    height: auto;
  }

  .reportsection2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  canvas {
    width: 100% !important;
    height: 600px !important;
  }

} */

/* @media (max-width:600px) {
  canvas {
    width: 100% !important;
    height: auto !important;
  }

  .reportHeadingMain {
    font-size: 20px;


  }

  .reportHeading {
    font-size: 16px;

  }

  .reportsection1 {
    padding: 10px;
  }

  .reportInnerSection {
    padding: 10px;
  }
} */