.conInfoConatiner {
  background-image: url("../../Images/supRegBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.conInfoConatiner2 {
  width: 442px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 40px;
}
.registerMainHeading {
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  font-family: Roboto Slab;
  font-size: 32px;
  font-weight: 700;
  line-height: 42.2px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  padding-bottom: 10px;
}
.supRegStepMain {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.radioText {
  padding-top: 20px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  line-height: 17.58px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.registerInputMain {
  display: flex;
  padding-top: 15px;
  width: 100%;
}
.registerInputMain2 {
  display: flex;
  padding-top: 15px;
  width: 432px;
}
.registerInputMain3 {
  display: flex;
  flex-direction: column;
  padding-top: 15px;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.inputRegisterLeft {
  width: 100%;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.inputRegisterLeft2 {
  width: 100%;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.inputRegisterRight {
  width: 100%;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.inputRegister {
  margin-top: 10px;
  width: 207px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(74, 79, 84, 1);
}
.inputRegister2 {
  margin-top: 10px;
  width: 96px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(74, 79, 84, 1);
}
.inputRegister3 {
  margin-top: 10px;
  width: 322px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(74, 79, 84, 1);
}
.inputRegister4 {
  margin-top: 10px;
  width: 432px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(74, 79, 84, 1);
}
.registerCheckbox {
  padding-top: 20px;
  display: flex;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.RegBtnIcnMain {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.RegBtnIcn2 {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 157px;
  height: 50px;
  border-radius: 9px;
  background: rgba(2, 86, 36, 1);
  border: 3px solid rgba(255, 255, 255, 1);
  font-family: Russo One;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.28px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.RegBtnIcn2 span {
  padding-left: 10px;
}
.RegBtnIcn2:hover {
  background: rgba(74, 79, 84, 1);
}

@media (max-width: 768px) {
  .registerMainHeading {
    gap: 10px;
    font-size: 28px;
  }
  .conInfoConatiner2 {
    width: 90%;
  }

  .fileUploadContainer {
    width: 100%;
  }
  .fileName {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .customFileUpload {
    height: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 5px;
    font-size: 13px;
  }
  .inputRegisterLeft2 {
    width: 96px;
  }
  .inputRegister {
    width: 100%;
  }

  .inputRegister3 {
    width: 100%;
  }
  .inputRegister4 {
    width: 100%;
  }
  .registerInputMain {
    display: flex;
    gap: 20px;
  }
  .registerInputMain2 {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .radioMaindiv {
    flex-wrap: wrap;
  }
  .radioMaindiv2 {
    flex-wrap: wrap;
  }
}
@media (max-width: 500px) {
  .registerMainHeading {
    gap: 14px;

    font-size: 27px;
    line-height: 32px;
  }
  .regIcn {
    width: 45px;
  }
}




.errorText{
  color: red;
}

